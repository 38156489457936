import React, { lazy } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { HelmetProvider } from 'react-helmet-async';

const App = lazy(() => import('./App'));
const LoadingComponent = lazy(() => import('./components/LoadingComponent'));
const AuthProvider = lazy(() => import('./contexts/AuthContext').then(module => ({ 
  default: module.AuthProvider 
})));

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
     <HelmetProvider> 
    <AuthProvider>
      <App />
    </AuthProvider>
    </HelmetProvider> 
  </React.StrictMode>
);